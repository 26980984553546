import { useTranslation } from 'react-i18next';
import { EditorDefaultSidePanelType, setActiveSidePanel } from '../../../grid/reduxStore/blockStyleSettingsSlice.ts';
import { SecondaryMenu } from '../../component/index.ts';

import {
  DEFAULT_FONT_FAMILY_OPTIONS,
  DEFAULT_FONT_SIZES,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_LINE_HEIGHT_OPTIONS,
} from 'components/editor/grid/reduxStore/defaultTextDefaultValue.ts';
import { useAppDispatch, useAppSelector } from 'components/editor/grid/reduxStore/Store.ts';
import { useContext, useMemo, useState } from 'react';
import { useInstalledFonts } from '../../../../../hooks/useInstalledFonts.ts';
import { useEditorContent } from '../../../../../providers/EditorContentContext.tsx';
import {
  selectAllTextDefaultStyles,
  TextDefaultStylingState,
  UpdateTextDefaultStylingPayloadType,
  TextDefaultTagSelectors,
  TextDefaultStylingType,
} from '../../../grid/reduxStore/textDefaultStylingSlice.ts';
import ColorPicker from '../../../shared/components/ColorPicker/ColorPicker.tsx';
import SidePanelAccordion from './SidePanelAccordion.tsx';
import StyledComponentInput from './StyledComponentInput.tsx';
import TextAlignmentButtons from './TextAlignmentButtons.tsx';
import TextStyleButtons from './TextStyleButtons.tsx';
import { DocumentSettingsContext } from '../../document-settings/DocumentDesignSettings/DocumentSettingsContext.tsx';

const fontSizeOptions = DEFAULT_FONT_SIZES.map((option) => option.name);
const lineHeightMenuItems = DEFAULT_LINE_HEIGHT_OPTIONS.map(({ name }) => name);
const lineHeightMenuItemsVal = Object.fromEntries(DEFAULT_LINE_HEIGHT_OPTIONS.map(({ name, level }) => [name, level]));

const generateAccordionData = ({
  allAvailableFonts,
  translation,
  textDefaultStyles,
  handleTextDefaultStyleUpdate,
}: {
  allAvailableFonts: string[];
  translation: (value: string) => string;
  textDefaultStyles: TextDefaultStylingState;
  handleTextDefaultStyleUpdate: ({ type, style }: UpdateTextDefaultStylingPayloadType) => void;
}) => {
  const handleTextDefaultStyleChange = (
    type: TextDefaultTagSelectors,
    styleToUpdate: keyof TextDefaultStylingType,
    value: TextDefaultStylingType[keyof TextDefaultStylingType]
  ) => {
    handleTextDefaultStyleUpdate({ type, style: { [styleToUpdate]: value } });
  };

  return Object.keys(textDefaultStyles).map((value) => {
    const selector = value as TextDefaultTagSelectors;
    const translationKey = `document.grid.default_styles.${selector}`;
    const title = translation(translationKey);

    return {
      title,
      content: [
        {
          componentTitle: translation('document.grid.default_styles.font'),
          components: [
            <StyledComponentInput
              handleChange={(selectChangeEvent) =>
                handleTextDefaultStyleChange(selector, 'fontFamily', selectChangeEvent.target.value + 'px')
              }
              value={textDefaultStyles[selector].fontFamily as string}
              width="144px"
              key={`font-family-${title}`}
              menuItems={allAvailableFonts}
            />,
          ],
        },
        {
          componentTitle: translation('document.grid.default_styles.font_size'),
          components: [
            <StyledComponentInput
              handleChange={(selectChangeEvent) => handleTextDefaultStyleChange(selector, 'fontSize', selectChangeEvent.target.value)}
              value={textDefaultStyles[selector].fontSize as string}
              width="50px"
              key={`font-size-${title}`}
              menuItems={fontSizeOptions}
            />,
          ],
        },
        {
          componentTitle: translation('document.grid.default_styles.color'),
          components: [
            <ColorPicker
              key={`color-${title}`}
              currentColor={textDefaultStyles[selector].color as string}
              handleColorChange={(color) => handleTextDefaultStyleChange(selector, 'color', color)}
            />,
          ],
        },
        {
          componentTitle: translation('document.grid.default_styles.text_style'),
          components: (
            <TextStyleButtons
              key={`text-style-${title}`}
              onChange={(attributeName, value) => handleTextDefaultStyleChange(selector, attributeName, value)}
              textDefaultStyles={textDefaultStyles[selector]}
            />
          ),
        },
        {
          componentTitle: translation('document.grid.default_styles.line_spacing'),
          components: [
            <StyledComponentInput
              key={`line-spacing-${title}`}
              value={
                DEFAULT_LINE_HEIGHT_OPTIONS.find((lineHeight) => lineHeight.level === textDefaultStyles[selector].lineHeight)?.name ??
                DEFAULT_LINE_HEIGHT
              }
              menuItems={lineHeightMenuItems}
              handleChange={(selectChangeEvent) =>
                handleTextDefaultStyleChange(selector, 'lineHeight', lineHeightMenuItemsVal[selectChangeEvent.target.value as string])
              }
              width="84px"
            />,
          ],
        },
        {
          componentTitle: translation('document.grid.default_styles.text_alignment'),
          components: (
            <TextAlignmentButtons
              key={`text-alignment-${title}`}
              onChange={(alignment) => handleTextDefaultStyleChange(selector, 'textAlign', alignment)}
              activeAlignment={textDefaultStyles[selector].textAlign}
            />
          ),
        },
      ],
    };
  });
};

export function DefaultStyleSettings() {
  const [expandedSection, setExpandedSection] = useState<string>('');

  const textDefaultStyles = useAppSelector(selectAllTextDefaultStyles);
  const { updateTextDefaultStyle } = useContext(DocumentSettingsContext);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { contentId } = useEditorContent();

  const { result: installedFonts } = useInstalledFonts(contentId);

  const allAvailableFonts = useMemo(
    () => [...DEFAULT_FONT_FAMILY_OPTIONS, ...(installedFonts || [])].map((font) => font.name),
    [installedFonts]
  );

  const accordionData = useMemo(
    () =>
      generateAccordionData({ allAvailableFonts, translation: t, textDefaultStyles, handleTextDefaultStyleUpdate: updateTextDefaultStyle }),
    [allAvailableFonts, t, textDefaultStyles]
  );

  const handleBackButtonClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
  };

  return (
    <SecondaryMenu
      testId={'default-styles-settings'}
      menuTitle={t('document.grid.default_styles.title')}
      backButtonOnClick={handleBackButtonClick}
      rowGap="0px"
    >
      {accordionData?.map((accordion, index) => (
        <SidePanelAccordion
          key={`accordion-${index}`}
          accordion={accordion}
          expanded={expandedSection}
          onChange={(value) => setExpandedSection(value)}
        />
      ))}
    </SecondaryMenu>
  );
}
